::-webkit-scrollbar {
  width: 10px;
  border-radius: 0px;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0px;
  background: white;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #e7e7e7;
}
.login-page {
  height: 100vh;
  overflow-y: auto;
}
.login-page .login-area {
  width: 100%;
  height: 26.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.login-page .login-area .banner-img-wrap {
  height: 100%;
  width: 100%;
}
.login-page .login-area .banner-img-wrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-page .login-area .login-box {
  height: 21.5rem;
  width: 21rem;
  background-color: #FFFFFF;
  position: absolute;
  right: 19%;
  top: 2.6rem;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}
.login-page .login-area .login-box .qr-code {
  position: absolute;
  width: 3.125rem;
  height: 3.125rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
.login-page .login-area .login-box .qr-code .qr-code-tip {
  position: absolute;
  right: 1.875rem;
  top: .5rem;
  background: #333333;
  padding: .375rem .6875rem;
  color: #FFFFFF;
  font-size: .6875rem;
  border-radius: 4px;
  display: none;
  white-space: nowrap;
}
.login-page .login-area .login-box .qr-code .qr-code-tip::after {
  content: "";
  height: 4px;
  width: 4px;
  display: block;
  position: absolute;
  background: #333333;
  transform: rotate(45deg);
  right: -2px;
  top: 12px;
}
.login-page .login-area .login-box .qr-code img {
  display: block;
  width: 100%;
  height: 100%;
}
.login-page .login-area .login-box .qr-code:hover .qr-code-tip {
  display: block;
}
.login-page .login-area .login-box .title {
  font-size: .875rem;
  font-weight: 600;
  color: #333333;
  margin-top: 3.375rem;
}
.login-page .login-area .login-box .form {
  padding: 0 1.875rem;
  box-sizing: border-box;
}
.login-page .login-area .login-box .form /deep/ .el-form .el-form-item {
  margin: 0;
}
.login-page .login-area .login-box .form /deep/ .el-form .el-form-item .el-form-item__content {
  line-height: 0 !important;
}
.login-page .login-area .login-box .form /deep/ .el-input .el-input__inner {
  height: 2.125rem !important;
}
.login-page .login-area .login-box .form .form-item {
  margin-top: 1.125rem;
  position: relative;
}
.login-page .login-area .login-box .form .form-item /deep/ .vcode-input .el-input__inner {
  padding-right: 7.5rem;
}
.login-page .login-area .login-box .form .form-item /deep/ .vcode-input .el-input__suffix {
  right: 6rem;
}
.login-page .login-area .login-box .form .form-item .Vcode {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.login-page .login-area .login-box .form .form-item .Vcode .line {
  width: 1px;
  height: 1.25rem;
  background: #DDDDDD;
}
.login-page .login-area .login-box .form .form-item .Vcode .get-code {
  font-size: .875rem;
  color: #2878FF;
  width: 90px;
  text-align: center;
}
.login-page .login-area .login-box .form .form-btn {
  margin-top: 1.25rem;
  width: 100%;
  background: #2878FF;
  border-radius: 2px;
  color: white;
  font-size: .875rem;
  border: none;
  height: 2.125rem !important;
  transition: 0.3s;
}
.login-page .login-area .login-box .form .form-btn:hover {
  background: #005cfb;
}
.login-page .login-area .login-box .form .form-btn:active {
  background: #5d98fd;
}
.login-page .login-area .login-box .form .agree-wrap {
  display: flex;
  margin-top: 0.875rem;
  word-break: break-all;
}
.login-page .login-area .login-box .form .agree-wrap .el-checkbox {
  margin-right: 4px;
  margin-top: 1px;
}
.login-page .login-area .login-box .form .agree-wrap .agree {
  font-size: .75rem;
  font-weight: 400;
  color: #999999;
}
.login-page .login-area .login-box .form .agree-wrap span {
  cursor: pointer;
  color: #2878FF;
}
.login-page .login-area .login-box .form .register {
  margin-top: .9375rem;
  font-size: .6875rem;
  font-weight: 400;
  color: #999999;
  display: flex;
}
.login-page .login-area .login-box .form .register .go-register {
  margin-left: 7px;
  color: #2878FF;
  cursor: pointer;
}
.login-page .login-area .login-box .form .code-img-border {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EEEEEE;
  margin-top: 12px;
}
.login-page .login-area .login-box .form .code-img-border .code-img-wrap {
  width: 9rem;
  height: 9rem;
  overflow: hidden;
}
.login-page .login-area .login-box .form .title-tips {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  color: #666666;
}
.login-page .service-sector {
  width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  justify-content: space-between;
}
.login-page .service-sector .item {
  width: 23.75rem;
  height: 11.5625rem;
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(229, 235, 251, 0.6);
  border-radius: 4px;
  transition: 0.3s;
  margin-top: 34px;
  padding: 1.875rem 2.5rem;
}
.login-page .service-sector .item:nth-child(1) {
  margin-top: 0;
}
.login-page .service-sector .item:nth-child(2) {
  margin-top: 0;
}
.login-page .service-sector .item:nth-child(3) {
  margin-top: 0;
}
.login-page .service-sector .item .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
}
.login-page .service-sector .item .detail {
  font-size: .875rem;
  font-weight: 400;
  color: #666666;
  margin-top: .75rem;
}
.login-page .service-sector .item .consulting {
  display: flex;
  align-items: center;
  font-size: .75rem;
  color: #2878FF;
  margin-top: 1rem;
  cursor: pointer;
}
.login-page .service-sector .item:hover {
  background-color: #669BF5;
}
.login-page .service-sector .item:hover .title {
  color: white;
}
.login-page .service-sector .item:hover .detail {
  color: white;
}
.login-page .service-sector .item:hover .consulting {
  color: white;
}
