





















































































































































































































































































































































































































































































































































.loginContainer {
  .loginContent {
    height: 100vh;
    background-image: url("../../assets/loginMongolia_banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20.625rem;
    .loginInput-wrap{
        display: flex;
        flex-direction: column;
        width: 322px;
        height: 322px;
        box-shadow: 0px 0px 23px 0px rgba(57,126,255,0.1);
        border-radius: 9px;
        background-color: white;
        padding: 30px;
        .el-form{
            .el-form-item{
                position: relative;
                border-bottom: 1px solid #ccc;
                .icon{
                    display: block;
                    height: 20px;
                    position: absolute;
                    top: 10px;
                    left: 2px;
                    z-index: 1;
                }
                .codeImg{
                    cursor: pointer;
                }
                /deep/.el-input .el-input__inner{
                    padding-left:40px;
                    border: none;
                }
            }
        }
        .loginButton-forget{
            padding-left:2px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #397EFF;
        }
        .loginButton{
            .el-button{
                width: 100%;
                height: 45px;
                background: #397EFF;
                border-radius: 5px;
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
  }
}
