






































































































































































































































































































































































































































































































.login {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  background: url("../../assets/login-qiye-bg.png") center center no-repeat;
  background-size: cover;
  .login_1 {
    position: absolute;
    left: 60px;
    top: 60px;
    width: 128px;
    height: 38px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .login_2_wrap {
    display: flex;
    margin-bottom: calc(3% + 49px);
    box-shadow: 0px 0px 60px 0px rgba(189,208,227,0.16);
  }
  .login_2 {
    width: 470px;
    height: 470px;
    background-color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 40px 60px;
    box-sizing: border-box;
      .wrap {
        padding: 25px;
        .tabs {
          width: 100%;
          .tab {
            text-align: center;
            width: 50%;
            position: relative;
            display: inline-block;
            font-size: 15px;
            margin-bottom: 30px;
            font-weight: 500;
            color: #333333;
            user-select: none;
            cursor: pointer;
          }
          .tab.active {
            color: #2878ff;
          }
          .tab.active::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -23.5px;
            width: 45px;
            height: 2px;
            background: #2878ff;
            border-radius: 1px;
          }
        }
        .loginForm {
          .onSubmit {
            width: 100%;
            margin-top: 10px;
          }
          /deep/ .el-input__suffix{
            line-height: 50px;
          }
          .yzm {
            border-bottom: 1px solid #f7f9fe;
            display: flex;
            // overflow: hidden;
            align-items: center;
            /deep/.el-form-item__content {
              display: flex;
              // overflow: hidden;
              align-items: center;
            }
            .loginput input {
              border: none;
            }
          }
        }
      }
      p {
        font-size: 11px;
        line-height: 1.8em;
        color: #666666;
      }
  }
  .login_3 {
    position: absolute;
    bottom: 20px;
    p {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
.login .login_2_bg {
  width: 470px;
  height: 470px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    height: 100%;
  }
}
.login /deep/ .el-form .el-input__prefix {
  font-size: 20px;
}
.login /deep/ .el-form .el-input__inner {
  border: none !important;
  height: 50px;
  border-bottom: 1px solid #f7f9fe !important;
}
/deep/ .el-dialog__body{
  text-align: left;
}
.loginFoot {
  position: absolute;
  left: 50%;
  margin-left: -490px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: #999999;
  width: 980px;
  justify-content: center;
  padding-bottom: 15px;
  line-height: 32px !important;
  font-size: 14px;
  .loginFoot_con{
    display: flex;
    justify-content: center;
  }
  span {
    font-size: 14px;
    font-family: PingFang SC;
  }
  .loginpadding {
    // padding: 20px 0;
    span {
      color: #999999;
      padding-left: 5px;
    }

    .el-link.el-link--default:hover {
      color: #fff;
    }
  }
}
