

























.topToolbarContainer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  width: 100%;
  height: 3.3125rem;
  .top-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    max-width:63.25rem;
    margin: 0 auto;
    .logo {
      cursor: pointer;
      overflow: hidden;
      img{
          display: block;
          width: 2rem;
          height: 2rem;
      }
      margin-right: .9375rem;
    }
    .title {
      font-size: 1.0625rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
