.loginContainer {
  margin-top: 62px;
  background-color: #fff !important;
  height: 100%;
  overflow-y: scroll;
}
.loginContainer::-webkit-scrollbar {
  width: 0 !important;
}
.main {
  width: 1440px;
  margin: -96px auto 0;
  padding-bottom: 60px;
  position: relative;
  z-index: 50;
}
.entrance {
  display: flex;
  align-items: center;
}
.entrance .entranceItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  height: 180px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
  border-radius: 12px;
  transition: 0.5s;
}
.entrance .entranceItem:hover {
  background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.7);
}
.entrance .entranceItem:hover .zxewm {
  display: block;
}
.entrance .entranceItem:before {
  content: '';
  position: absolute;
  width: 57px;
  height: 57px;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url("../../assets/loginIndexNew/homeTipBg1.png");
  background-size: cover;
}
.entrance .entranceItem:after {
  content: '';
  position: absolute;
  width: 81px;
  height: 81px;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: url("../../assets/loginIndexNew/homeTipBg2.png");
  background-size: cover;
}
.entrance .entranceItem:last-child {
  margin-right: 0;
}
.entrance .entranceItem h3 {
  font-size: 18px;
  color: #333;
}
.entrance .entranceItem p {
  margin-top: 8px;
  margin-bottom: 19px;
  font-size: 14px;
  color: #666;
}
.entrance .entranceItem /deep/ .el-button--primary.is-plain {
  transition: 0.5s;
  border-color: #999;
  color: #666;
  background-color: #fff;
}
.entrance .entranceItem /deep/ .el-button--primary.is-plain .el-icon--right {
  color: #2878FF;
}
.entrance .entranceItem /deep/ .el-button--primary.is-plain:hover {
  border-color: #2878FF;
  color: #fff;
  background-color: #2878FF;
}
.entrance .entranceItem /deep/ .el-button--primary.is-plain:hover .el-icon--right {
  color: #fff;
}
.commonTitle {
  color: #333;
  line-height: 1.5;
  font-weight: bold;
  margin: 40px 0 40px;
  text-align: center;
  font-size: 30px;
}
.onlineTools {
  display: flex;
  flex-wrap: wrap;
}
.onlineTools .onlineToolsItem {
  width: calc(50% - 15px);
  height: 272px;
  transition: 0.5s;
  background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
  border-radius: 12px 12px 12px 12px;
  padding: 10px 10px 0 40px;
  border: 1px solid transparent;
}
.onlineTools .onlineToolsItem:nth-child(1),
.onlineTools .onlineToolsItem:nth-child(2) {
  margin-bottom: 30px;
}
.onlineTools .onlineToolsItem:nth-child(1),
.onlineTools .onlineToolsItem:nth-child(3) {
  margin-right: 30px;
}
.onlineTools .onlineToolsItem:hover {
  background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
  border: 1px solid #fff;
}
.onlineTools .onlineToolsItem:hover .onlineToolsItemInfo img {
  opacity: 1;
}
.onlineTools .onlineToolsItem .onlineToolsItemInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.onlineTools .onlineToolsItem .onlineToolsItemInfo h3 {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}
.onlineTools .onlineToolsItem .onlineToolsItemInfo img {
  transition: 0.5s;
  opacity: 0.8;
}
.onlineTools .onlineToolsItem .onlineToolsItemBtns {
  margin: 10px 42px 0 12px;
  display: flex;
}
.onlineTools .onlineToolsItem .onlineToolsItemBtns span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 30px;
  height: 42px;
  font-size: 16px;
  color: #333;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.5s;
  cursor: pointer;
}
.onlineTools .onlineToolsItem .onlineToolsItemBtns span:nth-child(1) {
  margin-left: 0;
}
.onlineTools .onlineToolsItem .onlineToolsItemBtns span:hover {
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.1);
  color: #2878FF;
}
.manageModel {
  display: flex;
}
.manageModel .manageModelItem {
  position: relative;
  flex: 1;
  height: 512px;
  border-radius: 12px;
  background: linear-gradient(0deg, #F3F5F8 0%, #F9F9F9 100%);
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
  border: 1px solid #FFFFFF;
  padding: 0 30px;
  transition: 0.5s;
  z-index: 1;
  cursor: pointer;
}
.manageModel .manageModelItem:hover {
  z-index: 5;
  transform: scale(1.1, 1.1);
  background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
  box-shadow: 0px 10px 30px 1px #E0E4EE;
  border: 1px solid transparent;
}
.manageModel .manageModelItem:hover .manageModelImg img {
  opacity: 1;
}
.manageModel .manageModelItem:hover .manageModelBtn {
  color: #fff;
  background: #2878FF;
}
.manageModel .manageModelItem .labelTip {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  border-radius: 12px 0px 12px 0px;
  background: #2878FF;
}
.manageModel .manageModelItem .labelTip.labelTip-color1 {
  background: #FC987E;
}
.manageModel .manageModelItem .labelTip.labelTip-color2 {
  background: #7ADDE4;
}
.manageModel .manageModelItem .manageModelImg {
  display: flex;
  justify-content: center;
  height: 180px;
  margin-top: 60px;
}
.manageModel .manageModelItem .manageModelImg img {
  width: 180px !important;
  height: 180px !important;
  opacity: 0.8;
  transition: 0.5s;
}
.manageModel .manageModelItem .manageModelBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 58px;
  background: #fff;
  color: #333;
  font-size: 16px;
  border-radius: 0px 0px 12px 12px;
  transition: 0.5s;
}
.manageModel .manageModelItem > h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 90px;
}
.manageModel .manageModelItem > p {
  font-size: 16px;
  color: #333;
  margin-top: 16px;
}
.invitationBar {
  text-align: center;
  background-image: url("../../assets/loginIndexNew/blueBgImg.png");
  background-position: center;
  background-size: cover;
  height: 260px;
}
.invitationBar h3 {
  padding-top: 73px;
  font-size: 36px;
  color: #fff;
}
.invitationBar span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  width: 130px;
  height: 42px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: 0.5s;
}
.invitationBar span:hover {
  box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.2);
  color: #2878ff;
}
@media (max-width: 1500px) {
  .main {
    width: 1200px;
  }
  .onlineTools .onlineToolsItem .onlineToolsItemInfo h3 {
    font-size: 22px;
  }
  .manageModel .manageModelItem {
    padding: 0 20px;
  }
  .manageModel .manageModelItem > h3 {
    font-size: 22px;
  }
}
