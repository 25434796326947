

























































































































































































































































































































































































// 滚动条整体部分
::-webkit-scrollbar {
    width:10px;
    border-radius: 0px;
}
// 内层轨道 
::-webkit-scrollbar-track-piece{
    border-radius: 0px;
    background: white;
}
// 滚的条
::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #e7e7e7;
}
.login-page{
    height: 100vh;
    overflow-y: auto;
    // 登录区域
    .login-area{
        width: 100%;
        height: 26.25rem;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        // 轮播图
        .banner-img-wrap{
            height: 100%;
            width: 100%;
            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .login-box{
            height: 21.5rem;
            width: 21rem;
            background-color: #FFFFFF;
            position: absolute;
            right: 19%;
            top: 2.6rem;
            box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 10;
            // 二维码
            .qr-code{
                position: absolute;
                width: 3.125rem;
                height: 3.125rem;
                top: 0;
                right: 0;
                cursor: pointer;
                .qr-code-tip{
                    position: absolute;
                    right: 1.875rem;
                    top: .5rem;
                    background: #333333;
                    padding: .375rem .6875rem;
                    color: #FFFFFF;
                    font-size: .6875rem;
                    border-radius: 4px;
                    display: none;
                    white-space: nowrap;
                    &::after{
                        content: "";
                        height: 4px;
                        width: 4px;
                        display: block;
                        position: absolute;
                        background: #333333;
                        transform: rotate(45deg);
                        right: -2px;
                        top:12px;
                    }
                }
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                &:hover .qr-code-tip{
                    display: block;
                }
            }
            .title{
                font-size: .875rem;
                font-weight: 600;
                color: #333333;
                margin-top: 3.375rem;
            }
            // 表单区域
            .form{
                padding: 0 1.875rem;
                box-sizing: border-box;
                /deep/.el-form{
                    .el-form-item{
                        margin: 0;
                        .el-form-item__content{
                            line-height: 0 !important;
                        }
                    }
                }
                // 输入框
                /deep/.el-input{
                    .el-input__inner{
                        height: 2.125rem !important;
                    }
                }
                .form-item{
                    margin-top: 1.125rem;
                    position: relative;
                    // 验证码的输入框
                    /deep/.vcode-input{
                        .el-input__inner{
                            padding-right: 7.5rem;
                        }
                        .el-input__suffix{
                            right: 6rem;
                        }
                    }
                    .Vcode{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        .line{
                            width: 1px;
                            height: 1.25rem;
                            background: #DDDDDD;
                        }
                        .get-code{
                            font-size: .875rem;
                            color: #2878FF;
                            width: 90px;
                            text-align: center;
                        }
                    }
                }
                // 登录按钮
                .form-btn{
                    margin-top: 1.25rem;
                    width: 100%;
                    background: #2878FF;
                    border-radius: 2px;
                    color: white;
                    font-size: .875rem;
                    border: none;
                    height: 2.125rem !important;
                    transition: 0.3s;
                    &:hover{
                        background: #005cfb;
                    }
                    &:active{
                        background: #5d98fd;
                    }
                }
                // 同意协议
                .agree-wrap{
                    display: flex;
                    margin-top: 0.875rem;
                    word-break: break-all;
                    .el-checkbox{
                        margin-right: 4px;
                        margin-top: 1px;
                    }
                    .agree{
                        font-size: .75rem;
                        font-weight: 400;
                        color: #999999;
                    }
                    span{
                        cursor: pointer;
                        color: #2878FF;
                    }
                }
                // 没有账号？去注册
                .register{
                    margin-top: .9375rem;
                    font-size: .6875rem;
                    font-weight: 400;
                    color: #999999;
                    display: flex;
                    .go-register{
                        margin-left: 7px;
                        color: #2878FF;
                        cursor: pointer;
                    }
                }
                // 微信扫码登录
                .code-img-border{
                    width: 10rem;
                    height: 10rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #EEEEEE;
                    margin-top: 12px;
                    .code-img-wrap{
                        width: 9rem;
                        height: 9rem;
                        overflow: hidden;
                        // img{
                        //     width: 100%;
                        //     height: 100%;
                        // }
                    }
                }
                .title-tips{
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1rem;
                    color: #666666;
                }
            }
        }
    }
    // 服务板块区域
    .service-sector{
        width: 75rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0;
        justify-content: space-between;
        .item{
            width: 23.75rem;
            height: 11.5625rem;
            background-color: white;
            box-shadow: 0px 0px 20px 0px rgba(229,235,251,0.6);
            border-radius: 4px;
            transition: 0.3s;
            margin-top: 34px;
            padding: 1.875rem 2.5rem;
            &:nth-child(1){
                margin-top: 0;
            }
            &:nth-child(2){
                margin-top: 0;
            }
            &:nth-child(3){
                margin-top: 0;
            }
            // 标题
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
            }
            .detail{
                font-size: .875rem;
                font-weight: 400;
                color: #666666;
                margin-top: .75rem;
            }
            .consulting{
                display: flex;
                align-items: center;
                font-size: .75rem;
                color: #2878FF;
                margin-top: 1rem;
                cursor: pointer;
            }
            &:hover{
                background-color: #669BF5;
            }
            &:hover .title{
                color: white;
            }
            &:hover .detail{
                color: white;
            }
            &:hover .consulting{
                color: white;
            }
        }
    }
}
